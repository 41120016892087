import React from "react";
import Page from "../components/Page";
import Layout from "../components/Layout";
import { graphql } from "gatsby";
import StoryblokService from "../utils/storyblok-service";
import { PageContext } from "src/utils/context";
import SetCookie from "src/utils/SetCookie";
import ContentHeadingCentered from "src/components/ContentHeadingCentered";
import GetCookie from "src/utils/GetCookie";

export default class PageTemplate extends React.Component {
  state = {
    story: {
      content: this.props.data.story
        ? JSON.parse(this.props.data.story.content)
        : {},
    },
    cta_referrer: null,
    gated_content_allowed: false,
  };

  async getInitialStory() {
    StoryblokService.setQuery(this.props.location.search);
    let {
      data: { story },
    } = await StoryblokService.get(
      `cdn/stories/${this.props.data.story.full_slug}`
    );
    return story;
  }

  async componentDidMount() {
    let story = await this.getInitialStory()
    if(story.content) this.setState({ story })
    setTimeout(() => StoryblokService.initEditor(this), 200)
    if( this.getGatedContentAllowed() ) {
      this.setGatedContentAllowed(this.getGatedContentAllowed())
    }
    if( this.getCtaReferrer() ) {
      this.setCtaReferrer(this.getCtaReferrer())
    } 
  }

  getGatedContentAllowed = () => {
    return document.cookie.replace(/(?:(?:^|.*;\s*)gated_content_allowed\s*\s*([^;]*).*$)|^.*$/, "$1") ? true : false;
  }

  setGatedContentAllowed = (value) => {
    SetCookie(`gated_content_allowed=${value}`)
    this.setState({ gated_content_allowed: value })
  }

  getCtaReferrer = () => {
    return GetCookie("cta_referrer");
  }  

  setCtaReferrer = (value) => {
    SetCookie(`cta_referrer=${value}`)
    this.setState({ cta_referrer: value })
  }

  render() {
    const toggleContentHeadingCentered = [
      "articles",
      "webinars",
      "case_studies",
      "whitepaper",
      "use_cases",
      "ebooks",
      "customer_guides",
      "news_&_awards",
    ].includes(this.state.story.content.component);

    return (
      <PageContext.Provider
        value={{
          hasHero:
            this.state.story.content.body &&
            ["hero", "hero-custom"].includes(
              this.state.story.content.body[0]?.component
            )
              ? true
              : toggleContentHeadingCentered
              ? true
              : false,
          currentPage: this.state.story.uuid,
          breadcrumb: this.props.pageContext.breadcrumb,
          location: this.props.location,
          name: this.state.story.name?this.state.story.name:this.props.data.story.name,
          first_published_at: this.state.story.first_published_at,
          content: this.state.story.content,
          setCtaReferrer: this.setCtaReferrer,
          ctaReferrer: this.state.cta_referrer,
          gatedContentAllowed: this.state.gated_content_allowed,
          setGatedContentAllowed: this.setGatedContentAllowed,
        }}
      >
        <Layout location={this.props.location}>
          {toggleContentHeadingCentered && (
            <ContentHeadingCentered {...this.state.story} name={this.props.data.story.name} notBlock/>
          )}
          <Page blok={this.state.story.content} />
        </Layout>
      </PageContext.Provider>
    );
  }
}

export const query = graphql`
  query storyBySlug($fullSlug: String!) {
    story: storyblokEntry(full_slug: { eq: $fullSlug }) {
      name
      content
      full_slug
      uuid
      first_published_at
    }
  }
`;
